<template>
  <div
    :class="[
      'bottom-operation',
      {
        'bottom-operation__direction': closeLeft,
      },
    ]"
  >
    <div
      v-if="!closeLeft"
      class="bottom-operation__left"
      aria-hidden="true"
      :adaflag="`${sum}${language.SHEIN_KEY_PWA_15499}`"
      :aria-label="`${sum}${language.SHEIN_KEY_PWA_15499}`"
    >
      <div>{{ sum }}</div>
      {{ language.SHEIN_KEY_PWA_15499 }}
    </div>
    <div class="bottom-operation__right">
      <s-button
        :type="['H56PX']"
        role="button"
        :adaflag="resetLang"
        :aria-label="resetLang"
        aria-hidden="true"
        block
        @click="reset"
      >
        {{ resetLang }}
      </s-button>
      <s-button
        :class="btnLoadingClass"
        :type="['primary', 'H56PX']"
        role="button"
        aria-hidden="true"
        :adaflag="`Close`"
        aria-label="Close"
        @click="done"
      >
        {{ language.SHEIN_KEY_PWA_15042 }}
        <s-loading 
          v-if="isShein" 
          :show="loading"
          size="small" 
          mode="light"
        />
      </s-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Button } from '@shein/sui-mobile'
import { isShein } from '../../../../product_list_v2/js/utils'
Vue.use(Button)

export default {
  name: 'BottomOperation',
  props: {
    sum: {
      type: Number,
      default: 0,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    closeLeft: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.isShein = isShein()
    return {
      resetLang: gbCommonInfo?.language?.SHEIN_KEY_PWA_25346 || 'Clear',
    }
  },
  computed: {
    btnLoadingClass({ loading, isShein }) {
      return { 'with-loading': isShein && loading }
    }
  },
  mounted() {
    this.$emit('componentReady')
  },
  methods: {
    reset() {
      if (this.loading) return
      this.$emit('reset')
    },
    done() {
      if (this.isShein && this.loading) return
      this.$emit('done')
    },
  },
}
</script>

<style lang="less">
.bottom-operation {
  .flexbox();
  .space-between();
  .align-center();
  height: 1.17rem;
  margin-left: var(--negative-margin, -0.32rem);
  margin-right: var(--negative-margin, -0.32rem);
  padding: 0 var(--operation-padding, 0.32rem);
  border-top: 1px solid #e5e5e5;
  &__direction {
    flex-direction: row-reverse;
  }

  &__left {
    color: #222;
    > div {
      .font-dpr(28px);
      font-weight: bold;
    }
  }
  &__right {
    .flexbox();
    .align-center();
    &_reset {
      color: #666;
      .font-dpr(32px);
    }
  }
}
.bottom-operation__right {
  .S-button {
    .font-dpr(32px);
    margin-left: 0.32rem;
    text-transform: capitalize;
    min-width: 0;
    padding: 0 0.32rem;
    font-weight: normal;
  }

  .with-loading {
    position: relative;
    color: transparent;
    .S-loading {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
    }
    .S-loading__common-inner {
      margin: 0 auto;
      width: 32px;
      height: 32px;
    }
    .S-loading__circular-path-under {
      stroke: rgba(255, 255, 255, .3);
    }
    .S-loading__circular-path {
      stroke: rgba(255, 255, 255, 1);
    }
  }

}
</style>
