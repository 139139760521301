<template>
  <div class="multi-select__breadCrumb">
    <span 
      class="multi-select__breadCrumb_title" 
      aria-hidden="true"
    >{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'SubTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
  .multi-select {
    &__breadCrumb {
      display: block;
      margin-bottom: .32rem;
      font-weight: 700;
      .font-dpr(24px);
      position: relative;
      min-height: 22px;
      line-height: 1.7;
      &_title {
        color: #999;
        font-weight: 400;
      }
    }
  }
</style>
