// 数据格式：下面3个字段是必须的，其他字段也可以任意传，筛选器change时，都会透传出去
// [{
//   label: 'women',
//   value: '2030',
//   children: []
// }]

<template>
  <div 
    class="filter-ctn__single-tree-select" 
    :class="{
      'filter-ctn__single-tree-select_bt': borderTop
    }"
    :data-type="type"
  >
    <!-- 树结构 -->
    <div
      v-if="type == 'tree'"
      class="single-tree-select__header"
    >
      <HeaderLink
        ref="headerLink"
        :title="title"
        :cate-links="cateLinks"
        :language="language"
        :selected="curValue"
        :anim-item-text="animItemText"
        :disabled="loading"
        @clickItem="clickItem"
      />
    </div>
    <!-- 非树结构 -->
    <SubTitle
      v-else
      :title="title"
    />

    <div class="single-tree-select__content">
      <SelectItem 
        v-for="(item, index) in dataList"
        :key="index"
        :active="item.value == curValue"
        :item="item"
        :disabled="loading"
        @clickItem="e => clickEachItem(e, item, index)"
      />
    </div>
    <div class="single-tree-select__operation">
      <BottomOperation
        :sum="sum"
        :language="language"
        :close-left="closeBottomLeft"
        :loading="loading"
        @reset="reset"
        @done="done"
      />
    </div>
  </div>
</template>

<script>
import BottomOperation from './UI/BottomOperation'
import SelectItem from './UI/SelectItem'
import HeaderLink from './UI/HeaderLink'
import singleMixin from '../mixins/singleSelectMixin'
import SubTitle from './UI/SubTitle'
import { emitEvent } from '../utils'

export default {
  name: 'SingleSelect',
  components: {
    BottomOperation,
    SelectItem,
    SubTitle,
    HeaderLink
  },
  mixins: [singleMixin],
  props: {
    sum: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    urlSelectedId: {
      type: [String, Number],
      default: ''
    }, // url当前选中项
    data: {
      type: [Object, Array],
      default: () => ({})
    },
    resetData: {
      type: Boolean,
      default: false
    },
    type: {
      type: String, 
      default: 'tree'
    },
    language: {
      type: Object,
      default: () => ({})
    },
    closeBottomLeft: {
      type: Boolean,
      default: false
    },
    borderTop: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cateLinks: [],
      dataList: [],
      calledFromProps: false,
    }
  },
  computed: {
    isTree () {
      return this.type == 'tree'
    },
    curValue () {
      return this.data.curValue
    }
  },
  watch: {
    data: {
      handler (data) {
        this.$nextTick(() => {
          if (!data?.data?.length) return
          // 回显
          if (this.urlSelectedId) {
            this.$set(this.data, 'curValue', this.urlSelectedId)
          } else {
            this.$set(this.data, 'curValue', '')
          }

          this.handleDataList()
        })
      },
      immediate: true
    },
    resetData (isReset) {
      if (isReset) {
        this.reset(true)
        this.$emit('update:resetData', false)
      }
    },
    curValue () {
      emitEvent(this.$parent, 'needHighlightNavBar', { highlight: !!this.curValue })
      
      this.handleDataList()
    }
  },
  methods: {
    handleDataList (params) {
      const { cb } = params || {}
      if (this.isTree) {
        this.dataList = this.handleData(this.data.data) || [] // mixin 方法
        if (cb && typeof cb == 'function') {
          cb(this.dataList)
        }
      } else {
        this.dataList = this.data.data || []
      }
    },
    clickEachItem (e, item, index) {
      if (this.isTree) {
        this.clickItem({ type: 'button', data: item, index }, e) // mixin 方法
      } else {
        let payload = {}

        if (this.curValue == item.value) {
          payload = Object.assign(payload, { data: { value: '', originalValue: item.value } })
        } else {
          payload = Object.assign(payload, { data: { ...item, originalValue: item.value } })
        }
        this.clickCateItem(payload)
      }
    },
    clickCateItem (payload) {
      const { data } = payload

      this.$set(this.data, 'curValue', data.value) // 根据地址引用原理，触发侧边筛选数计算

      this.handleDataList({
        cb: (children) => {
          Object.assign(payload, { children }) // 当前显示的子分类
        }
      })

      let _payload = { ...payload } 
      if (this.calledFromProps) {
        Object.assign(_payload, { from: 'propsCalled' })
        this.calledFromProps = false
      }

      emitEvent(this.$parent, 'singleSelectChange', _payload)
    },
    reset (calledFromProps) {
      if (this.curValue) {
        this.calledFromProps = calledFromProps
        this.clickCateItem({ data: { value: '' }, operation: 'reset' })
      }
    },
    done () {
      emitEvent(this.$parent, 'clickDone')
    },
  }
}
</script>

<style lang="less">
  .filter-ctn {
    &__single-tree-select {
      padding: .32rem .32rem 0;
      background: #fff;
      &_bt {
        border-top: 1px solid #e5e5e5;
      }
      .single-tree-select {
        &__header {
          margin-bottom: 0.32rem;
        }
        &__content {
          position: relative;
          .flexbox();
          flex-wrap: wrap;
          align-content: flex-start;
          height: 2.86rem;
          overflow-y: auto;
          .font-dpr(24px);
        }
      }
    }
  }
</style>
